<template>
   <div class="content">
         <div class="main">
            <div class="main-r">
                <div class="div1">
                    <div class="div1-1">
                        <el-tabs  @tab-click="handleClick">
                            <el-tab-pane label="用户管理" name="first"></el-tab-pane>
                            <el-tab-pane label="配置管理" name="second"></el-tab-pane>
                            <el-tab-pane label="角色管理" name="third"></el-tab-pane>
                            <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>
                        </el-tabs>
                    </div>

                    <input type="text" placeholder="输入产品名称/订单号搜索" class="ipt1" ref="val">
                    <button class="btn1" @click="btn2">查询</button>
                </div>

                <div class="div2">
                    <span class="span1">
                        需求名称
                    </span>
                    <span class="span2">
                        订单金额
                    </span>
                    <!-- <span class="span3">
                        订单状态
                    </span> -->
                     <div class="status">
                        <el-dropdown  placement="bottom-start" @command="handleCommand" trigger="click">
                        <span class="el-dropdown-link" >
                            {{info2}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" placement="start">
                            <el-dropdown-item command="待付款">待付款</el-dropdown-item>
                            <el-dropdown-item command="制作中">制作中</el-dropdown-item>
                            <el-dropdown-item command="待收货">待收货</el-dropdown-item>
                            <el-dropdown-item command="退款中">退款中</el-dropdown-item>
                            <el-dropdown-item command="交易完成">交易完成</el-dropdown-item>                  
                        </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <span class="span4">
                        交易操作
                    </span>
                </div>

                <ul class="ul1" v-for="(item,i) in list" :key="i">
                    <li>
                        <div class="divt">
                            <span class="span1">{{item.createDate}}</span>
                            <span>订单号：{{item.orderSn}}</span>
                            <span>{{item.storeShortName}}</span>

                        </div>
                        <div class="divb" v-for="(item1,i) in item.orderItemList" :key="i">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>{{item1.productName}}</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    ￥{{item.totalAmount}}
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">nosj</p>
                                   <router-link to="/biddingDetail"><p class="p2">订单详情</p></router-link> 
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>         
                </ul>
            </div>
        </div>

    </div>
   
</template>

<script>
    import * as api from '@/api/api';
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";

    export default {
        name: "index",
        components: {Aside},//注册组件
        data() {
            return {
                radio1: '上海',
                radio2: '上海',
                num1: 5,
                show:true,
                shows:false,
                info:"20",
                activeName:"",
                info1:"全部购买订单",
                info2:"比价状态",
                flagSelect:false,

                page:1,
                rows:10,
                list:[],   
                type:"0", //订单类型状态码
                txt:"", //模糊搜索查询参数
                status:"", //比价状态
                val:"",
            }
        },
        methods:{
            showCont(){
                this.show==true,
                this.shows=false
            },
            showConts(){
                this.show==false,
                this.shows=true
            },
            handleClick(){

            },
            btn1(){
                this.flagSelect=true
            },
            btn2(){ //点击查询
            let val = this.$refs.val.value;
            this.txt=val;
            let self = this;
            let searchParams = {page:self.page, rows:self.rows,type:self.type,type:self.type,txt:self.txt,status:self.status}
            api.purchaseOrder(searchParams).then(res => {//上方数据传进myPrice这个api内的params
                let list = res.data.data.resData;
                self.list=list;
            });
            this.$refs.val.value="";
            console.log(this.$refs.val.value)
            },
            handleCommand(command) {//点击下拉列表，变换头部内容
                this.info2=command;
                if(command=="待付款"){
                    this.status=0;
                }
                if(command=="制作中"){
                    
                    this.status=1;
                }
                if(command=="待收货"){
                    this.status=2;
                }
                if(command=="交易完成"){
                    this.status=3;
                }
                if(command=="交易关闭"){
                    this.status=4;
                }
                if(command=="退款中"){
                    this.status=5;
                }
            
                let self=this;
                let searchParams = {page:self.page, type:self.type,txt:self.txt,rows:self.rows,status:self.status}
                api.purchaseOrder(searchParams).then(res => {
                self.list=res.data.data.resData;
                console.log(res)
                // self.totalSize=res.data.data.totalSize; //共几条数据
            })
            },
            purchaseOrder() {
                let self = this;
                let searchParams = {page:self.page, rows:self.rows,type:self.type,txt:self.txt,status:self.status}
                api.purchaseOrder(searchParams).then(res => {
                    let list = res.data.data.resData;
                    self.list=list;
                    console.log(res)
                });
            },
        },
        mounted() {
        this.purchaseOrder(); //请求接口
          
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
   
    *{
        margin:0;
        padding:0;
    }
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;
     border-bottom: 1px solid #e5e5e5;
    }
    //............以下是下拉菜单样式........... //
    .el-dropdown-menu{
        width: 95px;
        text-align: center;
        top: 0px;
        font-size: 16px;
        margin-top: -2px;
        li{
          margin:0;
          border-bottom: none;
        }
    }
    .el-dropdown{
        width: 100px;
        text-align: center;
        cursor: pointer !important;
        font-size: 16px;
        color: #333;
    }
 
    //............以上是下拉菜单样式........... //
     .content{
        width: 100%;
        height: 1192px;
        background:#f5f5f5;
        font-size: 15px;
        /deep/ .main{
            width: 1200px;
            height:1129px;
            margin:0 auto;
            font-size: 15px;
            .div1{
                overflow: hidden;

                .div1-1{
                    margin:20px 0px;
                    .el-tabs__header{
                        margin:0 !important;
                    }
                }
                .ul-top{
                    width: 100%;
                    height:43px;
                    border-bottom: 1px solid #e5e5e5;
                    li{
                        float: left;
                        line-height:43px;
                        padding:0 20px;
                    }
                }
            }

            .main-l{
                width: 181px;
                height: 1129px;
                background:#fff;
                float: left;
            }
            /deep/ .main-r{
                width: 1000px;
                height: 990px;
                background: #fff;

                 // -------以下顶部tab样式---------
                .el-tabs__nav{
                    margin-left: 20px;
                }
               
                .el-tabs__item:hover{
                    color:black !important;
                }
                .el-tabs__active-bar{
                    background:#0db168;
                }
                .el-tabs__item.is-active{
                    color:black;
                }
                .el-tabs__item{
                    background: url("../../../assets/image/purchaseOrder-nav.png") no-repeat 0 8px;
                    background-size: 1px 24px;
                    font-size: 16px;
                }
                #tab-first{
                    background: none;
                }
                .el-tabs__nav-wrap::after{
                        height: 1px;
                        background-color: #f5f5f5;
                }
                // -------以上顶部tab样式---------
                .el-tabs__nav-scroll{
                    margin-top: 20px 0;
                }
                .el-button--primary{
                    width: 134px;
                    height: 30px;
                    border: 1px solid #e5e5e5;
                }
                .ipt1{
                    width: 193px;
                    height: 30px;
                    border: 1px solid #e5e5e5;
                    margin-left: 30px;
                    padding-left:12px;
                    color:#999;
                    
                }
                .btn1{
                    width: 46px;
                    height:30px;
                    margin-left: 18px;
                   background: #0db168;
                    border: 0;
                    color: #fff;
                    border-radius: 2px;
                }


               
                .div2{
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin:0 auto;
                    margin-top: 23px;
                    height: 43px;
                    background: #f5f5f5;
                   
                    line-height: 44px;
                    >span{
                        float: left;
                    }
                    .span1{
                        margin-left: 90px;
                    }
                    .span2{
                        margin-left: 477px;
                    }
                    
                     .status {
                         float:left;
                            margin-left: 58px;
                        .el-dropdown{
                            cursor: pointer;
                        }
                        
                    }
                    .span4{
                        margin-left: 25px;
                    }
                }

                  .ul1{
                      width: 962px;
                      margin:0 auto;
                      margin-top: 20px;
                      
                      border-bottom: 0;
                      li{
                          border: 1px solid #e5e5e5;
                          margin-top: 20px;
                        .divt{
                            width: 100%;
                            height: 44px;
                            background: #f5f5f5;
                            line-height: 44px;
                            
                            color:#666666;
                            span{
                                margin-left: 29px;
                            }
                            .span1{
                            margin-left: 14px;  
                            }
                        }
                        .divb{
                            width: 100%;
                            height: 120px;
                           
                           
                            .divb-l{
                                width: 445px;
                                height: 120px;
                                float: left;
                                .divb-l2{
                                    width:100px;
                                    height: 200px;
                                   
                                }
                                .divb-l1{
                                    width:82px;
                                    height: 82px;
                                    float: left;
                                   
                                    margin-left: 15px;
                                    margin-top: 20px;
                                }
                                
                                .divb-l2{
                                    float: left;
                                    width:212px;
                                    height: 82px;
                                    
                                    span{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: block;
                                        margin-top: 20px;
                                        margin-left: 23px;
                                        text-align: center;
                                        padding-left: 5px;
                                    } 
                                }
                            }
                            .divb-r{
                                float: right;
                                width: 515px;
                                height: 120px;
                                >div{
                                    float: left;
                                    margin-left: 21px;
                                }
                                .divb-rl{
                                    width: 100px;
                                        text-align: center;
                                        margin-top: 24px;
                                        margin-left: 164px;
                                        color: #ff0036;
                                }
                                .divb-rc{
                                    margin-left:40px;
                                    width: 100px;
                                    .p1{
                                        text-align: center;
                                        margin-top: 25px;
                                    } 
                                    .p2{
                                      text-align: center;
                                        margin-top: 15px;
                                        color:#343434;
                                    
                                    } 
                                }
                                .divb-rr{
                                    width: 78px;
                                    font-size: 15px;
                                    .p1{
                                      text-align: center;                            
                                        margin-top: 18px;
                                        width: 78px;
                                        height: 26px;
                                        border-radius: 3px;
                                       background: #0db168;
                                        color: #fff;
                                        text-align: center;
                                        line-height:26px;
                                    }
                                    .p2{
                                      text-align: center;                                       
                                        margin-top: 16px;
                                    }
                                }
                            }
                        }
                    }
                }


              
                .div3{
                    padding-left: 30px;
                }
                .div4{
                    width: 960px;
                    border: 1px solid #e5e5e5;
                     margin:0 auto;
                    .div4-1{
                        width: 960px;
                        height:44px;
                        background: #f5f5f5;
                        margin:0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;
                        span{
                            margin-left: 125px;
                        }
                        .span2{
                            margin-left: 250px;
                        }
                        .span3{
                            margin-left: 145px;
                        }
                        .span4{
                            margin-left: 160px;
                        }
                    }
                    .ul2{
                        margin:0 auto;
                        width: 960px;
                            height:120px;
                   
                        li{
                            width: 960px;
                            height:120px;
                            border-bottom: 4px solid #e5e5e5;
                            .ul2-lil{
                                width: 327px;
                                height: 100%;
                                // background: blue;
                                float: left;
                                
                            }
                            .ul2-lir{
                                width: 536px;
                                height: 100%;
                                float:right;
                            }
                        }
                    }
                     
                }
                .div5{
                    width: 960px;
                    margin: 0 auto;
                    height:94px;
                    line-height: 94px;
                    text-align: right;
                        span{
                            margin-right: 30px;
                        }
                        .teshu{
                            color:#fe0036;
                        }
                    .btn1{
                         width: 100px;
                        height:36px;
                        
                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color:#fff;
                    }
                   
                }
            }



        }
    }
   
</style>
